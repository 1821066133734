import axios from 'axios'
import React from 'react'


export default class Welcome extends React.Component {
    componentDidMount=async()=>{
        let res=await axios({
            url: 'https://gitlab.com/dodolantech/data/-/raw/master/saya.json',
            method: 'get',
            
          })
          console.log(res)
    }
    render() {
      return <h1>Hello, {this.props.name}</h1>;
    }
  }
